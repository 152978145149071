<form
  class="spot-container op-datepicker-modal"
  data-test-selector="op-datepicker-modal"
  [attr.id]="htmlId"
  #modalContainer
  data-indicator-name="modal"
  (submit)="doSave($event)"
  tabindex="0"
>
  <op-datepicker-banner
    [scheduleManually]="scheduleManually"
    class="op-datepicker-modal--banner"
  ></op-datepicker-banner>

  <div class="spot-container op-datepicker-modal--stretch-content">
    <op-datepicker-scheduling-toggle
      class="spot-form-field"
      name="scheduleManually"
      [disabled]="isRootUser"
      [(ngModel)]="scheduleManually"
      (ngModelChange)="changeSchedulingMode()"
    ></op-datepicker-scheduling-toggle>

    <op-datepicker-working-days-toggle
      class="spot-form-field"
      name="ignoreNonWorkingDays"
      [disabled]="isRootUser"
      [(ngModel)]="ignoreNonWorkingDays"
      (ngModelChange)="changeNonWorkingDays()"
    ></op-datepicker-working-days-toggle>

    <spot-form-field
      [label]="text.date"
    >
      <spot-text-field
        slot="input"
        name="date"
        class="op-datepicker-modal--date-field"
        [ngClass]="{ 'op-datepicker-modal--date-field_current': isSchedulable }"
        [(ngModel)]="date"
        (ngModelChange)="dateChangedManually$.next()"
        [showClearButton]="true"
      ></spot-text-field>
      <button
        slot="action"
        type="button"
        class="spot-link"
        [ngClass]="{ 'op-datepicker-modal--hidden-link': !isSchedulable }"
        (click)="setToday()"
        [textContent]="text.today">
      </button>
    </spot-form-field>

    <input
      #flatpickrTarget
      id="flatpickr-input"
      hidden
    >
  </div>

  <div class="spot-action-bar">
    <div class="spot-action-bar--right">
      <button
        type="button"
        (click)="doCancel()"
        class="spot-modal--cancel-button button spot-action-bar--action"
        data-test-selector="op-datepicker-modal--action"
        [textContent]="text.cancel"
      ></button>
      <button
        type="submit"
        class="button -primary spot-action-bar--action"
        data-test-selector="op-datepicker-modal--action"
        [textContent]="text.save"
      ></button>
    </div>
  </div>
</form>
